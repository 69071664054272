import React from "react"
import "./colourfulButton.scss"
import { genColor } from "../utils/color-util"

export const ColourfulButton = props => {
  return (
    <button
      onClick={props.onClick}
      style={{ backgroundColor: genColor() }}
      className={"colourful-button-container"}
    >
      {props.children}
    </button>
  )
}
