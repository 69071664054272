import React, { useState } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Stats } from "components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faEye } from "@fortawesome/free-solid-svg-icons"
import { ColourfulButton } from "../components/colourfulButton"

const MediumStatsDetails = ({ location }) => {
  const [orderBy, setOrderBy] = useState("none")

  const stories =
    location && location.state && location.state.stories
      ? location.state.stories
      : []

  const renderOrderActions = () => {
    return (
      <div className="medium-stats-orderby-btn-container">
        <ColourfulButton onClick={e => setOrderBy("none")} className="btn">
          <span className="btn-icon">
            <FontAwesomeIcon icon={faCalendar} />
          </span>
          Tarihe Göre Sırala
        </ColourfulButton>
        <ColourfulButton
          onClick={e => setOrderBy("view-count")}
          className="btn"
        >
          <span className="btn-icon">
            <FontAwesomeIcon icon={faEye} />
          </span>
          Görüntülemeye Göre Sırala
        </ColourfulButton>
      </div>
    )
  }

  return (
    <Layout isVisibleBack={true}>
      <SEO title="Medium Blog İstatistikleri" />
      <div>
        <h2>Blog Okunma İstatistikleri</h2>
        {true && renderOrderActions()}
        <Stats mode={"list-mode"} activeGroup={stories} orderBy={orderBy} />
      </div>
    </Layout>
  )
}

export default MediumStatsDetails
